import axios from "axios";
import {
  REGISTRATION,
  REGISTRATION_FAIL,
  PLANLIST,
  PLANLIST_FAIL,
  PLANLBYID,
  PLANLBYID_FAIL,
  SELECTED_PLAN_FROM_HOME,
  SCHOOL_LIST,
  SCHOOL_LIST_FAIL,
  CONTACT_US_MAIL,
  CONTACT_US_MAIL_FAIL,
  STAFF_LIST,
  STAFF_LIST_FAIL,
  STUDENT_LIST,
  STUDENT_LIST_FAIL,
} from "../ActionTypes";
import {
  config,
  CONTACT_US,
  NEW_SCHOOL_REGISTER_API,
  PLAN_LIST_API,
  PLAN_LIST_API_BY_ID,
  SCHOOL_LIST_API,
  STAFF_LIST_API,
  STUDENT_LIST_API,
} from "../constants";
import { toast } from "react-toastify";

export const schoolRegistration = (userObj: any) => {
  return async (dispatch: any) => {
    const body = JSON.stringify(userObj);
    localStorage.clear();
    return await axios
      .post(NEW_SCHOOL_REGISTER_API, body, config)
      .then((res) => {
        dispatch({
          type: REGISTRATION,
          payload: res.data,
        });
        toast.success("School successfully created");
        return res;
      })
      .catch((error) => {
        dispatch({
          type: REGISTRATION_FAIL,
        });
        toast.error(error.response.data.errorMsg);
      });
  };
};

export const planList = () => {
  return async (dispatch: any) => {
    return await axios
      .get(PLAN_LIST_API)
      .then((res) => {
        dispatch({
          type: PLANLIST,
          payload: res.data,
        });
        return res;
      })
      .catch((error) => {
        dispatch({
          type: PLANLIST_FAIL,
        });
        toast.error(error.response.data.errorMsg);
      });
  };
};

export const planListbyid = (id: any) => {
  return async (dispatch: any) => {
    return await axios
      .get(PLAN_LIST_API_BY_ID + id)
      .then((res) => {
        dispatch({
          type: PLANLBYID,
          payload: res.data,
        });
        return res;
      })
      .catch((error) => {
        dispatch({
          type: PLANLBYID_FAIL,
        });
        toast.error(error.response.data.errorMsg);
      });
  };
};

export const selectedPlan = (id: string) => {
  return async (dispatch: any) => {
    dispatch({
      type: SELECTED_PLAN_FROM_HOME,
      payload: id,
    });
  };
};

export const schoolList = () => {
  return async (dispatch: any) => {
    return await axios
      .get(`${SCHOOL_LIST_API}`)
      .then((res) => {
        dispatch({
          type: SCHOOL_LIST,
          payload: res.data,
        });
        return res;
      })
      .catch((error) => {
        dispatch({
          type: SCHOOL_LIST_FAIL,
        });
        toast.error(error.response.data.errorMsg);
      });
  };
};

export const staffList = () => {
  return async (dispatch: any) => {
    return await axios
      .get(`${STAFF_LIST_API}`)
      .then((res) => {
        dispatch({
          type: STAFF_LIST,
          payload: res.data,
        });
        return res;
      })
      .catch((error) => {
        dispatch({
          type: STAFF_LIST_FAIL,
        });
        toast.error(error.response.data.errorMsg);
      });
  };
};

export const studentList = () => {
  return async (dispatch: any) => {
    return await axios
      .get(`${STUDENT_LIST_API}`)
      .then((res) => {
        dispatch({
          type: STUDENT_LIST,
          payload: res.data,
        });
        return res;
      })
      .catch((error) => {
        dispatch({
          type: STUDENT_LIST_FAIL,
        });
        toast.error(error.response.data.errorMsg);
      });
  };
};

export const contactUs = async (userObj: any) => {
  const body = JSON.stringify(userObj);
  /*  localStorage.clear(); */
  return await axios
    .post(CONTACT_US, body, config)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      toast.error(error.response.data.errorMsg);
      return error.response.data;
    });
};
