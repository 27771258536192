import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CustomButtons from "../CustomButtons/Button";
import { useHistory } from "react-router-dom";
import * as AuthActions from "../../reducer-store/actions/authActions";
import OwlCarousel from "react-owl-carousel";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "owl.carousel/dist/assets/owl.carousel.css";

const useStyles = makeStyles((theme) => ({
  mainTitle: {
    fontSize: "44px",
    fontWeight: 700,
    color: "#000000",
  },
  subTitle: {
    fontSize: "18px",
  },
  featur: {
    textAlign: "center",
  },
  card_join: {
    backgroundColor: "#ffffff",
    boxShadow: "2px 2px 10px #9E9E9E",
    marginTop: "15px",
  },
  num_title: {
    fontWeight: 700,
    color: "#000000",
    fontSize: "30px",
  },
}));

const options = {
  // items: 2,
  autoplay: true,
  responsive: {
    0: {
      items: 1
    },
    600: {
      items: 1
    },
    1000: {
      items: 2
    }
  },
  navText: [
    '<i class="fa fa-angle-left" aria-hidden="true"></i>',
    '<i class="fa fa-angle-right" aria-hidden="true"></i>',
  ],
};

const Join: React.FC = () => {
  const schoolListInfo: any = useSelector((state: any) => state.schoolList);
  const { list, staffData, studentData } = schoolListInfo;
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { schoolList, staffList, studentList } = bindActionCreators(AuthActions, dispatch);
  // const [schoolListArr, setSchoolListArr] = useState<any>([]);

  useEffect(() => {
    (async () => {
      const resp: any = await schoolList();
      await staffList();
      await studentList();
      // const { data } = resp;
      // setSchoolListArr(data)
    })();
  }, []);

  if (list?.data === null) {
    return <>Please wait...</>;
  }

  return (
    <div>
      <section className="section mt-3" id="screenshot">
        <div className="icon-effects-w3-6">
          <img src="assets/images/bg1.png" />
        </div>
        <div className="container">
          <div className="row justify-content-center mb-5">
            <div className="col-md-8 col-lg-6 text-center">
              <h5 className="subtitle"><span className="fw-bold">Recently Joined Schools</span></h5>
              <Typography className="text-muted">
                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                Lorem Ipsum has been the industry's standard dummy text.
              </Typography>
            </div>
          </div>
          <Row className="mt-4">
            {list?.data?.length && (
              <OwlCarousel
                className="owl-theme"
                loop
                margin={10}
                {...options}
                nav
              >
                {list?.data?.slice(-10)?.map((item: any) => (
                  <div className="item">
                    <Row className="align-items-center">
                      <Col lg="3" xs='3'>
                        <div className="pic">
                          <img src="assets/images/icon1.png" />
                        </div>
                      </Col>
                      <Col lg="9" xs='9' >
                        <p className="description">{item.name}</p>
                      </Col>
                    </Row>
                  </div>
                ))}
              </OwlCarousel>
            )}
          </Row>
        </div>
      </section>

      <section className="section bg-light mt-3" id="contact">
        <div className="icon-effects-w3-5">
          <img src="assets/images/bg2.png" />
        </div>
        <div className="container">
          <Row className="mt-3 justify-content-center">
            <Col lg="4" sm="12">
              <Card className={classes.card_join}>
                <CardContent>
                  <Row>
                    <Col lg="4" xs='4'>
                      <img src="assets/images/count1.png" />
                    </Col>
                    <Col lg="8" xs='8' className="d-flex flex-column justify-content-center">
                      <Typography
                        className={classes.num_title}
                        gutterBottom
                        variant="h6"
                        component="div"
                      >
                        {list?.meta?.totalRecord}+
                      </Typography>
                      <Typography gutterBottom component="div">
                        Schools Joined
                      </Typography>
                    </Col>
                  </Row>
                </CardContent>
              </Card>
            </Col>
            <Col lg="4" sm="12">
              <Card className={classes.card_join}>
                <CardContent>
                  <Row>
                    <Col lg="4" xs='4'>
                      <img src="assets/images/count2.png" />
                    </Col>
                    <Col lg="8" xs='8' className="d-flex flex-column justify-content-center"
                    >
                      <Typography
                        className={classes.num_title}
                        gutterBottom
                        variant="h6"
                        component="div"
                      >
                        {Number(studentData?.activeStudentData + studentData?.inactiveStudentData)}+
                      </Typography>
                      <Typography gutterBottom component="div">
                        Students
                      </Typography>
                    </Col>
                  </Row>
                </CardContent>
              </Card>
            </Col>
            <Col lg="4" sm="12">
              <Card className={classes.card_join}>
                <CardContent>
                  <Row>
                    <Col lg="4" xs='4'>
                      <img src="assets/images/count3.png" />
                    </Col>
                    <Col lg="8" xs='8' className="d-flex flex-column justify-content-center"
                    >
                      <Typography
                        className={classes.num_title}
                        gutterBottom
                        variant="h6"
                        component="div"
                      >
                        {Number(staffData?.activeStaffData + staffData?.inactiveStaffData)}+
                      </Typography>
                      <Typography gutterBottom component="div">
                        Staff
                      </Typography>
                    </Col>
                  </Row>
                </CardContent>
              </Card>
            </Col>
          </Row>
          <Row className="text-center mt-4">
            <Col lg="12">
              <div className="text-center mt-4 mb-5">
                <CustomButtons.ColorButton
                  onClick={() => history.push("get-started")}
                >
                  Get Started
                </CustomButtons.ColorButton>
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </div>
  );
};

export default Join;
