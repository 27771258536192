import React from "react";
import { SCHOOL_LIST, STAFF_LIST, STUDENT_LIST } from "../ActionTypes";

const initialState = { list: null };

const SchoolStore: React.FC = (state: any = initialState, action: any = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SCHOOL_LIST: {
      return {
        ...state,
        list: payload,
      };
    }
    case STAFF_LIST: {
      return {
        ...state,
        staffData: payload,
      };
    }
    case STUDENT_LIST: {
      return {
        ...state,
        studentData: payload,
      };
    }
    default:
      return state;
  }
};

export default SchoolStore;
