export const ROUTES = {
  home: "/",
  about: "/about",
  features: "/features",
  yourPlan: "/your-plan",
  join: "/join",
  getStarted: "/get-started",
  thankYou: "/thank-you",
  contactUs: "/contact-us",
  contactingUs: "/contacting-us",
};
