import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Row, Col } from "react-bootstrap";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CheckIcon from "@material-ui/icons/Check";

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: "#000000",
  },
  accordion: {
    backgroundColor: "#000000",
    color: "#ffffff",
  },
  ExpandMoreIcon: {
    color: "#ffffff",
  },
  CardContent: {
    minHeight: "600px",
  },
  plan_title: {
    fontSize: "16px",
    fontWeight: 700,
    color: "#000000",
  },
}));

const PlanOne: React.FC<any> = ({ planData }) => {
  const classes = useStyles();
  return (
    // <Card className="geatchoice1 card2">
    //   <CardContent>
    //     <Row>
    //       <Col lg="6">
    //         <Typography
    //           className={classes.plan_title}
    //           variant="h6"
    //           component="div"
    //         >
    //           {planData.name}
    //           <br /> $ {planData.price} / year
    //         </Typography>
    //       </Col>
    //       <Col lg="6">
    //         <Row>
    //           <Col>
    //             <Typography className="Sttext1" variant="body2">
    //               <CheckIcon />
    //               &nbsp;{planData.staff} staff
    //             </Typography>
    //             <Typography className="Sttext1" variant="body2">
    //               <CheckIcon />
    //               &nbsp;{planData.instructor} instructor
    //             </Typography>
    //             <Typography className="Sttext1" variant="body2">
    //               <CheckIcon />
    //               &nbsp;{planData.admin} admin
    //             </Typography>
    //             <Typography className="Sttext1" variant="body2">
    //               <CheckIcon />
    //               &nbsp;1 owner
    //             </Typography>
    //             <Typography className="Sttext1" variant="body2">
    //               <CheckIcon />
    //               &nbsp;{planData.students} students per year
    //             </Typography>
    //           </Col>
    //         </Row>
    //       </Col>
    //     </Row>
    //   </CardContent>
    // </Card>

    <div className="container21">
      <div className="card21">
        <div className="card-primary">
          <div className="valign d-flex flex-column justify-content-center">
            <Typography className="price" variant="h5" component="div">
              <small>{planData.symbol}</small> {planData.price} <small>/</small>
               <small>Year</small>
            </Typography>
          </div>
        </div>
        <Row>
          <Col lg="6" className="mt-2">
            <div className="card123">
              <Typography className="price" variant="subtitle1" component="div">
                {planData.staff} Staff
              </Typography>
            </div>
          </Col>
          <Col lg="6" className="mt-2">
            <div className="card123">
              <Typography className="price" variant="subtitle1" component="div">
                {planData.instructor} Instructor
              </Typography>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg="6" className="mt-2">
            <div className="card123">
              <Typography className="price" variant="subtitle1" component="div">
                {planData.admin} Admin
              </Typography>
            </div>
          </Col>
          <Col lg="6" className="mt-2">
            <div className="card123">
              <Typography className="price" variant="subtitle1" component="div">
                {planData.students} Students per year
              </Typography>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg="6" className="mt-2">
            <div className="card123">
              <Typography className="price" variant="subtitle1" component="div">
                1 Owner
              </Typography>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default PlanOne;
