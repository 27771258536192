import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import CustomButtons from "../CustomButtons/Button";
import { useHistory } from "react-router-dom";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { useSelector } from "react-redux";
import { contactUs } from "../../reducer-store/actions/authActions";
import ToastMessage from "../CustomButtons/ToastMessage";
import { toast } from "react-toastify";
import { OTHER_SERVICE_MAIL_SEND_SUCCESSFULLY } from "../../reducer-store/messages/actionMessages";
import { clear } from "console";

const useStyles = makeStyles((theme) => ({
  thank_you: {
    /* textAlign: "center", */
    marginBottom: "80px",
  },
  mainTitle: {
    fontSize: "40px",
    fontWeight: "bolder",
    color: "#000000",
    textAlign: "center",
    "@media (max-width: 768px)": {
      fontSize: "32px !important",
    },
  },
  subTitle: {
    fontSize: "24px",
    fontWeight: 700,
    marginTop: "15px",
    textAlign: "center",
  },
  f_Til: {
    fontSize: "20px",
    fontWeight: 700,
    marginTop: "5px",
    textAlign: "center",
  },
}));

export default function Thank_u() {
  const history = useHistory();
  const classes = useStyles();
  const [refrence, setRefrence] = useState<any>([]);
  const lstorage: any = localStorage.getItem("school-register")
  const lData = JSON.parse(lstorage)

  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, []);

  const handleCheck = (data: any) => {
    let refrenceData = [...refrence]
    if (data.target.checked) {
      refrenceData = [...refrence, data.target.value]
    }
    else {
      refrenceData.splice(refrence.indexOf(data.target.value), 1)
    }
    setRefrence(refrenceData)
  };
  const submitHandler = async (e: any) => {
    e.preventDefault();
    let userObj = {
      email: lData.email,
      name: lData.name,
      school: lData.subdomain,
      mobile: lData.mobile,
      subject: "Other Services",
      body: "I want to get Detail information about mention service",
      interests: refrence.join()
    }
    let resData: any = await contactUs(userObj);
    if (!resData.error) {
      toast.success(OTHER_SERVICE_MAIL_SEND_SUCCESSFULLY);
      setTimeout(() => {
        history.push("/")
        localStorage.clear();
      }, 3000)
    };
  };

  return (
    <>
      <ToastMessage />
      <div>
        <section className="section-top-2 p-5 ">
          <div className={classes.thank_you}>
            <Typography
              className="thank-title fw-bold mb-2 display-5"
              variant="h3"
              component="div"
            >
              Welcome to the
              <br />
              Driving School Cloud Family
            </Typography>
            <Typography className="mt-2 text-center" component="div">
              You will soon receive email with your login details
            </Typography>
            <Typography className={classes.subTitle} variant="h4" component="div">
              You may be interested in.
            </Typography>

            <Container>
              <Row className="offset-md-4">
                <Col className="text-start" lg="4" sm="12">
                  <FormControlLabel
                    name="checkbox1"
                    control={<Checkbox id="1" color="primary" onChange={(e) => handleCheck(e)} />}
                    label="Homepage design"
                    value={"Homepage design"}
                  />
                </Col>
                <Col className="text-start" lg="4" sm="12">
                  <FormControlLabel
                    name="checkbox2"
                    control={<Checkbox color="primary" onChange={(e) => handleCheck(e)} />}
                    label="Onsite school setup"
                    value={"Onsite school setup"}
                  />
                </Col>
              </Row>
            </Container>

            <Container>
              <Row className="offset-md-4">
                <Col className="text-start" lg="4" sm="12">
                  <FormControlLabel
                    name="checkbox3"
                    control={<Checkbox color="primary" onChange={(e) => handleCheck(e)} />}
                    label="Online staff training"
                    value={"Online staff training"}
                  />
                </Col>
                <Col className="text-start" lg="4" sm="12">
                  <FormControlLabel
                    name="checkbox4"
                    control={<Checkbox color="primary" onChange={(e) => handleCheck(e)} />}
                    label="In class staff training"
                    value={"In class staff training"}
                  />
                </Col>
              </Row>
            </Container>

            {/* <div className="stline2"></div> */}
            <Container>
              <Row>
                <Col lg="6" className="offset-sm-3" sm="6">
                  <div className="stline"></div>
                </Col>
              </Row>
            </Container>
            <Typography className={classes.f_Til} variant="h4" component="div">
              Would you like us to contact you?
            </Typography>
            <div className="mt-4 text-center">
              {/*  <CustomButtons.OutlineColorButton onClick={() => history.push("")}>
              May be later
            </CustomButtons.OutlineColorButton>{" "} */}
              <CustomButtons.ColorButton
                onClick={submitHandler/* () => history.push("/") */}
              >
                Contact us
              </CustomButtons.ColorButton>
            </div>
          </div>
        </section>
      </div>
    </>

  );
}
