import React, { SetStateAction, useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { CardElement, Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";
import { useSelector } from "react-redux";
// import { PaymentInitStripeApi } from "../../../reducer-store/Actions";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import LockIcon from "@material-ui/icons/Lock";
import { makeStyles, withStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: "#000000",
  },
  accordion: {
    backgroundColor: "#000000",
    color: "#ffffff",
  },
  ExpandMoreIcon: {
    color: "#ffffff",
  },
  CardContent: {
    minHeight: "600px",
  },
  downlink: {
    color: "#000000",
    textDecoration: "none",

    "&:hover": {
      color: "#000000",
    },
  },
}));

type ElementCompProps = {
  handleStep: React.Dispatch<SetStateAction<number>>;
  stepValue: number;
  logo: any;
};

const stripePromise = loadStripe("pk_test_51JputsEggVol7ThpYTRlsiA06NmirAEmovr3GeCn6YTzjJmVJTmeLwFb9UU7zq0qZbUFSOfEaWyE6422yXmD05qd00kGweIaRE");

const ElementComp: React.FC<ElementCompProps> = ({ handleStep, stepValue, logo }) => {
  const { data }: any = useSelector((state: any) => state.Planbyid);
  // const [clientSecret, setClientSecret] = useState("");
  // const [paymentRes, setPaymentRes] = useState(null);
  const [loaded, setLoader] = useState(false);
  const classes = useStyles();

  return (
    <div>
      <section id="features" className="section-top-1">
        <Container className="">
          <Row className="mb-2">
            <Col lg="6">
              <div>
                <img src="assets/images/svg/make_payment.svg" width="100%" />
              </div>
            </Col>
            <Col lg="6" className="mt-2">
              <Card className="Main_Card">
                {loaded && <LinearProgress />}
                <CardContent className={classes.CardContent}>
                  <Row className="">
                    <Typography className="pay" variant="h5" component="div">
                      Payment Information (required)
                    </Typography>
                  </Row>
                  <Row className="mt-2">
                    <div style={{ display: 'flex', gridGap: '10px', alignItems: 'center' }}>
                      <LockIcon />
                      <Typography className="Sttext2" variant="body2">
                        SECURE SSL ENCRYPTED PAYMENT POWERED BY PAYMENT GATEWAY
                      </Typography>
                    </div>

                  </Row>
                  <Row className="mt-4">
                    <Elements stripe={stripePromise}>
                      <CheckoutForm
                        handleStep={handleStep}
                        stepValue={stepValue}
                        manageLoader={setLoader}
                        busLogo={logo}
                      />
                    </Elements>
                  </Row>
                </CardContent>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default ElementComp;
