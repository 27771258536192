import React, { useEffect, useState, SetStateAction } from "react";
import { Navbar, Nav, Form, Container, Row, Col } from "react-bootstrap";
import CustomButtons from "../CustomButtons/Button";
import { Redirect, useHistory } from "react-router-dom";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import CheckIcon from "@material-ui/icons/Check";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as AuthActions from "../../reducer-store/actions/authActions";
import Group from "../../assets/images/truckwhite.svg";
import Group1 from "../../assets/images/Group 1112.svg";
import Group2 from "../../assets/images/Group 1115.svg";
import Group3 from "../../assets/images/Group 1116.svg";
import Group4 from "../../assets/images/Group 1117.svg";
import Group5 from "../../assets/images/Path 314.svg";
import { ROUTES } from "../../constants/routes";

const useStyles = makeStyles((theme) => ({
  mainTitle: {
    fontSize: "44px",
    fontWeight: 700,
    color: "#000000",
  },
  subTitle: {
    fontSize: "18px",
  },
  featur: {
    textAlign: "center",
  },
  plan: {
    display: "flex",
    flexDirection: "column",
    minHeight: "610px",
  },

  addons_plan: {
    display: "flex",
    flexDirection: "column",
    minHeight: "610px",
    backgroundColor: "#00a651",
    position: "relative",
    overflow: "visible",
    "@media (max-width: 900px)": {
      marginTop: '20px'
    },
  },
  card: {
    backgroundColor: "#fedc92",
  },
  addons_card: {},
  plan_title: {
    fontSize: "24px",
    fontWeight: 700,
    color: "#000000",
  },
  addons_title: {
    paddingTop: "0px",
    fontSize: "24px",
    fontWeight: 700,
    color: "#ffffff",
  },
  img_title: {
    marginLeft: "10px",
    color: "#464646",
  },
  img_title1: {
    marginLeft: "10px",
    color: "#ffffff",
  },
  platform: {
    fontWeight: 700,
    color: "#000000",
    marginBottom: "10px",
  },
  Customplan_title: {
    fontSize: "18px",
    fontWeight: 700,
    color: "#00a651",
    textAlign: "center",
    marginTop: "20px",
  },
  // CardContent: {
  //   minHeight: "490px",
  // },
  bestplan: {
    content: "",
    display: "inline-block",
    width: "47px",
    height: "20px",
    backgroundColor: "#de9b0b",
    position: "absolute",
    top: "-10px",
    right: "10px",
    Transform: "rotate(1deg)",
    borderRadius: "8px",
    zIndex: -2,
  },
  bestplan1: {
    content: "",
    display: "inline-block",
    width: "80px",
    height: "80px",
    backgroundColor: "#feba26",
    position: "absolute",
    top: "-10px",
    right: "20px",
    borderRadius: "0px 0px 10px 10px",
  },
  offer: {
    fontSize: "14px",
    fontWeight: 600,
    color: "#ffffff",
    padding: "5px",
    textAlign: "center",
  },
}));

export default function Your_plan() {
  const schoolPlanList: any = useSelector((state: any) => state.planList);
  const { data } = schoolPlanList;

  const classes = useStyles();
  const history = useHistory();
  // const [posts, setPosts] = useState([]);
  const dispatch = useDispatch();
  const { planList, selectedPlan } = bindActionCreators(AuthActions, dispatch);

  useEffect(() => {
    (async () => {
      await planList();
    })();
  }, []);

  const handlePlanAndRedirect = (id: string) => {
    selectedPlan(id);
    return history.push(ROUTES.getStarted);
  };

  if (data === null) {
    return <>Please wait...</>;
  }

  return (
    <div>

      {/* <!-- Pricing start --> */}
      <section className="section" id="pricing">
        <div className="icon-effects-w3-3">
          <img src="assets/images/bg3.png" />
        </div>
        <div className="ypur_plan"></div>
        <div className="ypur_plan_2">
          <div className="container ">
            <div className="row justify-content-center mb-5">
              <div className="col-md-8 col-lg-6 text-center">
                <h5 className="subtitle"><span className="fw-bold">Choose Your Plan</span></h5>
                <Typography className="text-muted">
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry.<br />
                  Lorem Ipsum has been the industry's standard dummy text.
                </Typography>
              </div>
            </div>

            <Row className="justify-content-md-center">
              <Col lg="4" sm="12">
                <Card className={classes.plan}>
                  <CardMedia className={classes.card} component="div">
                    <CardContent>
                      <Typography
                        className={classes.plan_title}
                        variant="h6"
                        component="div"
                      >
                        {data && data[0].name}
                        <br />{" "}
                        {`${data && data[0].symbol} ${data && data[0].price}`} /
                        year
                      </Typography>
                    </CardContent>
                  </CardMedia>
                  <CardContent className="">
                    <Typography component="div">
                      One license for all course types.
                    </Typography>
                    <div className="stline"></div>
                    <Row>
                      <Col lg="6" sm="6" xs="6">
                        <img src="assets/images/az.png" />{" "}
                        <span className={classes.img_title}> AZ </span>
                      </Col>
                      <Col lg="6" sm="6" xs="6">
                        <img src="assets/images/dz.png" />{" "}
                        <span className={classes.img_title}> DZ </span>
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col lg="6" sm="6" xs="6">
                        <img src="assets/images/bz.png" />{" "}
                        <span className={classes.img_title}> BZ </span>
                      </Col>
                      <Col lg="6" sm="6" xs="6">
                        <img src="assets/images/cz.png" />{" "}
                        <span className={classes.img_title}> CZ </span>
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col lg="6" sm="6" xs="6">
                        <img src="assets/images/bike.png" />{" "}
                        <span className={classes.img_title}> Bike </span>
                      </Col>
                      <Col lg="6" sm="6" xs="6">
                        <img src="assets/images/car.png" />{" "}
                        <span className={classes.img_title}> Car </span>
                      </Col>
                    </Row>
                    <div className="stline"></div>
                    <Typography
                      className={classes.platform}
                      variant="h6"
                      component="div"
                    >
                      Following school, personas come with the platform.
                    </Typography>
                    <Typography component="div">
                      <CheckIcon />
                      &nbsp;
                      {data && data[0].staff} staff
                    </Typography>
                    <Typography component="div">
                      <CheckIcon />
                      &nbsp;
                      {data && data[0].instructor} instructor
                    </Typography>
                    <Typography component="div">
                      <CheckIcon />
                      &nbsp;
                      {data && data[0].admin} admin
                    </Typography>
                    <Typography component="div">
                      <CheckIcon />
                      &nbsp;1 owner
                    </Typography>
                    <Typography component="div">
                      <CheckIcon />
                      &nbsp;
                      {data && data[0].students} students per year
                    </Typography>
                  </CardContent>
                  <div className="pricing-plan">
                    <CustomButtons.OutlineButton
                      onClick={(e) => handlePlanAndRedirect(data && data[0].id)}
                    >
                      Buy Now
                    </CustomButtons.OutlineButton>
                  </div>
                </Card>
              </Col>
              <Col lg="4" sm="12">
                <Card className={classes.addons_plan}>
                  <div className={classes.bestplan}></div>
                  <div className={classes.bestplan1}>
                    <p className={classes.offer}>BEST OFFER PLAN</p>
                  </div>
                  <CardContent className="">
                    <Typography
                      className={classes.addons_title}
                      variant="h6"
                      component="div"
                    >
                      {data && data[1].name}
                      <br />{" "}
                      {`${data && data[1].symbol} ${data && data[1].price}`} /
                      year
                    </Typography>
                    <div className="stline_1"></div>
                    <Typography component="div" className="text-white">
                      One license for all course types.
                    </Typography>
                    <div className="stline_1"></div>
                    <Row>
                      <Col lg="6" sm="6" xs="6">
                        <img src={Group} />{" "}
                        <span className={classes.img_title1}> AZ </span>
                      </Col>
                      <Col lg="6" sm="6" xs="6">
                        <img src={Group5} />{" "}
                        <span className={classes.img_title1}> DZ </span>
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col lg="6" sm="6" xs="6">
                        <img src={Group2} />{" "}
                        <span className={classes.img_title1}> BZ </span>
                      </Col>
                      <Col lg="6" sm="6" xs="6">
                        <img src={Group4} />{" "}
                        <span className={classes.img_title1}> CZ </span>
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col lg="6" sm="6" xs="6">
                        <img src={Group1} />{" "}
                        <span className={classes.img_title1}> Bike </span>
                      </Col>
                      <Col lg="6" sm="6" xs="6">
                        <img src={Group3} />{" "}
                        <span className={classes.img_title1}> Car </span>
                      </Col>
                    </Row>
                    <div className="stline_1"></div>
                    <Typography
                      className="text-white mb-2"
                      variant="h6"
                      component="div"
                    >
                      Following school, personas come with the platform.
                    </Typography>
                    <Typography className="text-white" component="div">
                      <CheckIcon />
                      &nbsp;
                      {`${data[1].staff !== 99999
                        ? data[1].staff
                        : "Unlimited"
                        } Staff`}
                    </Typography>
                    <Typography className="text-white" component="div">
                      <CheckIcon />
                      &nbsp;
                      {`${data[1].instructor !== 99999
                        ? data[1].instructor
                        : "Unlimited"
                        } Instructor`}
                    </Typography>
                    <Typography className="text-white" component="div">
                      <CheckIcon />
                      &nbsp;
                      {`${data[1].admin !== 99999
                        ? data[1].admin
                        : "Unlimited"
                        } Admin`}
                    </Typography>
                    <Typography className="text-white" component="div">
                      <CheckIcon />
                      &nbsp;
                      1 owner
                    </Typography>
                    <Typography className="text-white" component="div">
                      <CheckIcon />
                      &nbsp;
                      {`${data[1].students !== 99999
                        ? data[1].students
                        : "Unlimited"
                        } Students per year`}
                    </Typography>
                  </CardContent>
                  <div className="pricing-plan">
                    <CustomButtons.OutlineWhiteButton
                      onClick={(e) => handlePlanAndRedirect(data && data[1].id)}
                    >
                      Buy Now
                    </CustomButtons.OutlineWhiteButton>
                  </div>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
        <div className="ypur_plan_1"></div>
      </section>
      {/* <!-- Pricing end --> */}







    </div>
  );
}
