import TextField from "@material-ui/core/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { IconButton, InputAdornment } from "@mui/material";
import {
  FormInputFieldProps,
  FormInputPasswordFieldProps,
  FormInputSelectFieldProps,
} from "../../ts/FormType";
import { renderRequiredError } from "./FormValidator";

export const InputField: React.FC<FormInputFieldProps> = ({
  name,
  type,
  rows,
  multiline,
  className,
  value,
  lable,
  placeHolder,
  handleChange,
  isValidValue,
  isSubmit,
  max,
  min,
  requiredMessage,
  disabled = false,
  isCheckValidEmailValue = false,
  isCheckValidPhoneValue = false,
  isCheckValidRID=false,
  isCheckValidAddress=false,
  isCheckValidBusiness= false
}) => {
  return (
    <div className={className}>
      <TextField
        InputLabelProps={{ shrink: true }}
        fullWidth
        rows={rows}
        multiline={multiline}
        type={type}
        label={lable}
        name={name}
        value={value}
        placeholder={placeHolder}
        onChange={handleChange}
        disabled={disabled}
        inputProps={{
          maxLength: max,
          minLength:min
        }}
      />
      {!isValidValue &&
        renderRequiredError(
          value,
          isSubmit,
          requiredMessage,
          isCheckValidEmailValue,
          isCheckValidPhoneValue,
          isCheckValidRID,
          isCheckValidAddress,
          isCheckValidBusiness
        )}
    </div>
  );
};

export const InputPasswordField: React.FC<FormInputPasswordFieldProps> = ({
  name,
  type,
  rows,
  multiline,
  className,
  value,
  lable,
  placeHolder,
  handleChange,
  isValidValue,
  isSubmit,
  requiredMessage,
  clickToShowPassowrd,
  hoverToPassword,
  showPassword,
  isCheckValidEmailValue = false,
  isCheckValidPhoneValue = false,
  isCheckValidRID = false,
  isCheckValidAddress= false,
  isCheckValidBusiness= false
}) => {
  return (
    <div className={className}>
      <TextField
        InputLabelProps={{ shrink: true }}
        fullWidth
        rows={rows}
        multiline={multiline}
        type={showPassword ? "text" : type}
        label={lable}
        name={name}
        value={value}
        placeholder={placeHolder}
        onChange={handleChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={clickToShowPassowrd}
                onMouseDown={hoverToPassword}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      // disabled={disabled}
      />
      {!isValidValue &&
        renderRequiredError(
          value,
          isSubmit,
          requiredMessage,
          isCheckValidEmailValue,
          isCheckValidPhoneValue,
          isCheckValidRID,
          isCheckValidAddress,
          isCheckValidBusiness
        )}
    </div>
  );
};

export const InputSelectField: React.FC<FormInputSelectFieldProps> = ({
  select,
  lable,
  name,
  className,
  value,
  options,
  handleChange,
  isValidValue,
  isSubmit,
  requiredMessage,
  isCheckValidEmailValue = false,
  isCheckValidPhoneValue = false,
  isCheckValidRID= false,
  isCheckValidAddress = false,
  isCheckValidBusiness= false
}) => {
  return (
    <div className={className}>
      <TextField
        InputLabelProps={{ shrink: true }}
        fullWidth
        select={select}
        SelectProps={{
          native: true,
        }}
        label={lable}
        name={name}
        value={value}
        onChange={handleChange}
      >
        <option key="empty_province" value="">{`Select ${lable}`}</option>
        {options!==undefined ? options.map((option) => (
          <option key={option.id} value={option.id}>
            {option.name}
          </option>
        )):""}
      </TextField>
      {!isValidValue &&
        renderRequiredError(
          value,
          isSubmit,
          requiredMessage,
          isCheckValidEmailValue,
          isCheckValidPhoneValue,
          isCheckValidRID,
          isCheckValidAddress,
          isCheckValidBusiness
        )}
    </div>
  );
};
