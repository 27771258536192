import axios from "axios";
import { toast } from "react-toastify";
import {
  CHECK_EMAIL_MOBILE,
  config,
  GET_SINGLE_PLAN_DETAIL,
  PAYMENT_INIT,
  GET_PROVINCE_LIST,
  COUNTRY_LIST,
  GET_PROVINCE_TAX,
} from "./constants";

export const GetPlanList = async (planId: string) => {
  const { data }: any = await axios.get(`${GET_SINGLE_PLAN_DETAIL}/${planId}`);
  return data.data;
};

export const PaymentInitStripeApi = async (userObj: any) => {
  return await axios
    .post(PAYMENT_INIT, userObj, config)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      toast.error(error.response.data.errorMsg);
    });
};

export const CheckEmailMobile = async (userObj: any) => {
  const body = JSON.stringify(userObj);
  return await axios
    .post(CHECK_EMAIL_MOBILE, body, config)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
      // toast.error(error.response.data.errorMsg);
    });
};

export const GetProvinceList = async () => {
  const { data }: any = await axios.get(GET_PROVINCE_LIST);
  return data.data[0].Provinces;
};

export const GetProvinceTax = async (province_id: string) => {
  const { data }: any = await axios.get(`${GET_PROVINCE_TAX}?province_id=${province_id}`);
  return data.data[0];
};

export const GetCountryAndProvinceList = async () => {
  const { data }: any = await axios.get(COUNTRY_LIST);
  return data.data;
};
