import React, { useState, useEffect } from 'react';
import CustomButtons from "../CustomButtons/Button";
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { Container, Row, Col } from 'react-bootstrap';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import LinearProgress from '@material-ui/core/LinearProgress';
import Alert from '@material-ui/lab/Alert';
import {
    InputField,
    InputSelectField,
} from "../FormComponent/FormFieldsComponent";
import { isRequiredField, isValidEmail, isValidPhone } from "../FormComponent/FormValidator";
import * as Acttion from "../../reducer-store/actions/authActions"
import { bindActionCreators } from "redux";
import { useDispatch } from "react-redux";
import ToastMessage from '../CustomButtons/ToastMessage';
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
    CardContent: {
        minHeight: '45 0px'
    }
}));

export default function Contact_us() {
    const [loaded, setLoader] = useState(false);
    const [isValidValues, setIsValidValues] = useState(true);
    const [isSubmit, setIsSubmit] = useState(false);
    const [schoolName, setSchoolName] = useState("");
    const [userName, setUserName] = useState("");
    const [mobile, setMobile] = useState("");
    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");
    const dispatch = useDispatch();
    const { contactUs } = bindActionCreators(Acttion, dispatch);

    useEffect(() => {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }, [])
    const classes = useStyles();
    const history = useHistory();

    const SubmitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsSubmit(true);
        if (
            isRequiredField(schoolName) &&
            isRequiredField(userName) &&
            isRequiredField(mobile) &&
            isValidPhone(mobile) &&
            isRequiredField(email) &&
            isValidEmail(email) &&
            isRequiredField(message)) {
            setLoader(true);
            setIsSubmit(false);
            let userObj = {
                school: schoolName,
                mobile: mobile,
                email: email,
                name: userName,
                subject: subject,
                body: message
            }
            let resData: any = await contactUs(userObj);
            if (!resData.error) {
                setLoader(false);
                setSchoolName("");
                setUserName("");
                setEmail("");
                setMobile("");
                setMessage("");
                setSubject("");
                setTimeout(() => {
                    setLoader(false);
                    history.push('');
                  }, 2000);
                return;
            }
            toast.success("Thank you for conatct us, Our executive will contact you as soon as possible");
        }
        setLoader(false);
        setIsSubmit(false);
        setIsValidValues(false);
    }

    return (
        <div>
            <ToastMessage />
            <section className="section-top-2">
                <Container className="">
                    <Row>
                        <Col lg="6">
                            <div>
                                <img src="assets/images/svg/contact_us.svg" />
                            </div>
                        </Col>
                        <Col className='mt-5' lg="6">
                            {/* <Alert icon={false} severity="success">Thank you for conatct us, Our executive will contact you as soon as possible</Alert> */}
                            <br />
                            <Card className="Main_Card">
                                {loaded && <LinearProgress />}
                                <form onSubmit={SubmitHandler}>
                                    <CardContent className={classes.CardContent}>
                                        <Row>
                                            <Col lg="12" sm="12" className="lableMain">
                                                <InputField
                                                    type="text"
                                                    lable="School Name"
                                                    name={schoolName}
                                                    rows={1}
                                                    multiline={false}
                                                    placeHolder="Enter School Name"
                                                    className="form-textfield-styles"
                                                    value={schoolName}
                                                    handleChange={(e) =>
                                                        setSchoolName(e.target.value)
                                                    }
                                                    isValidValue={isValidValues}
                                                    isSubmit={isSubmit}
                                                    requiredMessage="School Name field is required"
                                                    isCheckValidRID={true}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col lg="12" sm="12" className="lableMain">
                                                <InputField
                                                    type="text"
                                                    lable="Your Name"
                                                    name={userName}
                                                    rows={1}
                                                    multiline={false}
                                                    placeHolder="Enter Your Name"
                                                    className="form-textfield-styles"
                                                    value={userName}
                                                    handleChange={(e) =>
                                                        setUserName(e.target.value)
                                                    }
                                                    isValidValue={isValidValues}
                                                    isSubmit={isSubmit}
                                                    requiredMessage="Your Name is required"
                                                    isCheckValidRID={true}

                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col lg="12" sm="12" className="lableMain">
                                                <InputField
                                                    type="text"
                                                    lable="Mobile"
                                                    name={mobile}
                                                    rows={1}
                                                    multiline={false}
                                                    placeHolder="Enter Mobile Number"
                                                    className="form-textfield-styles"
                                                    value={mobile}
                                                    handleChange={(e) =>
                                                        setMobile(e.target.value)
                                                    }
                                                    isValidValue={isValidValues}
                                                    isSubmit={isSubmit}
                                                    requiredMessage="Mobile Number is required"
                                                    isCheckValidRID={true}
                                                    isCheckValidPhoneValue={true}
                                                    max={10}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col lg="12" sm="12" className="lableMain">
                                                <InputField
                                                    type="text"
                                                    lable="Email"
                                                    name={email}
                                                    rows={1}
                                                    multiline={false}
                                                    placeHolder="Enter Email Address"
                                                    className="form-textfield-styles"
                                                    value={email}
                                                    handleChange={(e) =>
                                                        setEmail(e.target.value)
                                                    }
                                                    isValidValue={isValidValues}
                                                    isSubmit={isSubmit}
                                                    requiredMessage="Email is required"
                                                    isCheckValidRID={true}
                                                    isCheckValidEmailValue={true}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col lg="12" sm="12" className="lableMain">
                                                <InputField
                                                    type="text"
                                                    lable="Subject"
                                                    name={subject}
                                                    rows={1}
                                                    multiline={false}
                                                    placeHolder="Enter Subject"
                                                    className="form-textfield-styles"
                                                    value={subject}
                                                    handleChange={(e) =>
                                                        setSubject(e.target.value)
                                                    }
                                                    isValidValue={isValidValues}
                                                    isSubmit={isSubmit}
                                                    requiredMessage="Subject is required"
                                                    isCheckValidRID={true}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col lg="12" sm="12" className="lableMain">
                                                <InputField
                                                    type="text"
                                                    lable="Message"
                                                    name={message}
                                                    rows={1}
                                                    multiline={false}
                                                    placeHolder="Enter Message"
                                                    className="form-textfield-styles"
                                                    value={message}
                                                    handleChange={(e) =>
                                                        setMessage(e.target.value)
                                                    }
                                                    isValidValue={isValidValues}
                                                    isSubmit={isSubmit}
                                                    requiredMessage="Message is required"
                                                    isCheckValidRID={true}
                                                />
                                            </Col>
                                        </Row>
                                    </CardContent>
                                    <CardContent>
                                        <div className="mt-3 text-end">
                                            <CustomButtons.OutlineColorButton onClick={() => history.push('get-started')}> Back </CustomButtons.OutlineColorButton>{' '}
                                            <CustomButtons.ColorButton type="submit"> Submit </CustomButtons.ColorButton>
                                        </div>
                                    </CardContent>
                                </form>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}
