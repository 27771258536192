import React from "react";
import { CONTACT_US_MAIL, CONTACT_US_MAIL_FAIL} from "../ActionTypes";

const initialState = { Contact: null };

const ContactUs: React.FC = (state: any = initialState, action: any = {}) => {
  const { type, payload } = action;
  switch (type) {
    case CONTACT_US_MAIL: {
      return {
        ...state,
        Contact: payload.data,
      };
    }
    case CONTACT_US_MAIL_FAIL: {
        return {
          ...state,
          Contact: null,
        };
      }
    default:
      return state;
  }
};

export default ContactUs;
