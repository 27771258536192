import { PLANLIST, PLANLBYID, SELECTED_PLAN_FROM_HOME } from "../ActionTypes";
const planStoreData: any = localStorage.getItem("school-plan");
const planbyidStoreData: any = localStorage.getItem("school-plan-byid");
const initialState = { data: JSON.parse(planStoreData) };
const initialStatebyid = { data: JSON.parse(planbyidStoreData) };

const PlanStore = (state: any = initialState, action: any = {}) => {
  const { type, payload } = action;
  switch (type) {
    case PLANLIST: {
      localStorage.setItem("school-plan", JSON.stringify(payload.data));
      return {
        ...state,
        data: payload.data,
      };
    }
    case SELECTED_PLAN_FROM_HOME: {
      return { ...state, selectedPlanFromHome: payload };
    }
    default:
      return state;
  }
};

const PlanbyidStore = (state: any = initialStatebyid, action: any = {}) => {
  const { type, payload } = action;
  switch (type) {
    case PLANLBYID: {
      // localStorage.setItem("school-plan-byid", JSON.stringify(payload.data));
      return {
        ...state,
        data: payload.data,
      };
    }
    default:
      return state;
  }
};

export { PlanbyidStore, PlanStore };
