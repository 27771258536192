import React, { SetStateAction, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import CardContent from "@material-ui/core/CardContent";
import axios from "axios";
import { config, NEW_SCHOOL_REGISTER_API, PAYMENT_INIT, } from "../../../reducer-store/constants";
import { REGISTRATION, REGISTRATION_FAIL, SELECTED_PLAN_FROM_HOME } from "../../../reducer-store/ActionTypes";
import CustomButtons from "../../CustomButtons/Button";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Container, Row, Col } from "react-bootstrap";
// import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { ROUTES } from "../../../constants/routes";
import { Button } from "@mui/material";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
// import LinearProgress from "@material-ui/core/LinearProgress";

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: "#000000",
  },
  accordion: {
    backgroundColor: "#000000",
    color: "#ffffff",
  },
  ExpandMoreIcon: {
    color: "#ffffff",
  },
  CardContent: {
    minHeight: "600px",
  },
  downlink: {
    color: "#000000",
    textDecoration: "none",
    "&:hover": {
      color: "#000000",
    },
  },
}));

type CheckoutFormProps = {
  handleStep: React.Dispatch<SetStateAction<number>>;
  stepValue: number;
  manageLoader: React.Dispatch<SetStateAction<boolean>>;
  busLogo: any;
};

const CheckoutForm: React.FC<CheckoutFormProps> = ({
  handleStep,
  stepValue,
  manageLoader,
  busLogo
}) => {
  const { data }: any = useSelector((state: any) => state.Planbyid);
  const dispatch = useDispatch();
  const history = useHistory();
  const stripe = useStripe();
  const element = useElements();
  const [show, setShow] = React.useState(false);
  const [cardValid, setCardValid] = React.useState(false);
  // const [loaded, setLoader] = useState(false);

  // const selectedPlanDetail: any = localStorage.getItem("school-plan-byid");
  // let { id } = JSON.parse(selectedPlanDetail);
  const schoolDetails: any = localStorage.getItem("school-register");

  let {
    name,
    /* owner_name, */
    first_name,
    last_name,
    middle_name,
    title,
    whatsapp,
    business_number,
    email,
    mobile,
    phone,
    address_1,
    address_2,
    state,
    registry_id,
    tin_number,
    tenant_id,
    subdomain,
    province,
    logo
  } = JSON.parse(schoolDetails);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    manageLoader(true);
    /* setLoader(true); */
    if (!stripe || !element) {
      manageLoader(false);
      return;
    }

    if (!checked) {
      setShow(true)
      manageLoader(false);
    }
    else {
      setShow(false)
      const card_element = element.getElement(CardElement);
      if (!card_element) {
        manageLoader(false);
        return;
      }

      const res = await fetch(PAYMENT_INIT, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          plan_id: data.id,
          gateway_name: "stripe",
          currency: "cad",
          province_id: province,
        }),
      });
      console.log("first", res)
      const resdata = await res.json();
      const clientSecretID = await resdata.data.clientSecret;

      setCardValid(false);
      const get_payment_intent: any = await stripe.confirmCardPayment(
        clientSecretID,
        {
          payment_method: {
            card: card_element,
          },
        }
      );

      console.log("get_payment_intent", get_payment_intent)

      if (get_payment_intent.error) {
        setCardValid(true);
        manageLoader(false);
        return;
      }
      setCardValid(false);
      const { paymentIntent } = await get_payment_intent;
      const formData = new FormData();
      formData.append("payment_id", paymentIntent.id);
      formData.append("plan_id", data.id);
      formData.append("name", name);
      formData.append("subdomain", subdomain);
      /* formData.append("owner_name", owner_name); */
      formData.append("first_name", first_name);
      formData.append("last_name", last_name);
      formData.append("middle_name", middle_name);
      formData.append("title", title);
      formData.append("whatsapp", whatsapp);
      formData.append("business_number", business_number);
      formData.append("email", email);
      formData.append("mobile", mobile);
      formData.append("phone", phone);
      formData.append("address_1", address_1);
      formData.append("address_2", address_2);
      formData.append("state", state);
      formData.append("registry_id", registry_id);
      formData.append("tin_number", tin_number);
      formData.append("tenant_id", tenant_id);
      formData.append("logo", busLogo);
      formData.append("status", "1");

      return await axios
        .post(NEW_SCHOOL_REGISTER_API, formData, config)
        .then((res) => {
          dispatch({
            type: REGISTRATION,
            payload: { data: null },
          });
          dispatch({
            type: SELECTED_PLAN_FROM_HOME,
            payload: "1",
          });
          toast.success(`Your payment was successful... ${" "}We will email you once the registration is processed.`);
          setTimeout(() => {
            manageLoader(false);
            history.push(ROUTES.thankYou);
            /*  localStorage.clear(); */
          }, 10000);
          return res;
        })
        .catch((error) => {
          manageLoader(false);
          dispatch({
            type: REGISTRATION_FAIL,
          });
          toast.error(error.response.data.errorMsg);
        });
    }
  };

  const handleBack = () => {
    setTimeout(() => {
      handleStep((stepValue) => stepValue - 1);
    }, 2000);
  };

  const classes = useStyles();

  const [checked, setChecked] = React.useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    if (checked === true) {
      setShow(false);
    }
  };

  return (
    <>
      {/* <h1>stripe form</h1> */}
      {/* {loaded && <LinearProgress />} */}
      <Container className="">
        <form id="payment-form" onSubmit={handleSubmit}>
          <CardElement id="payment-element" options={{ hidePostalCode: true }} />
          {cardValid === true ? <p style={{ color: "red", marginLeft: 25 }}>Invalid Card detail, please enter valid card detail</p> : null}
          <Row className="mt-1 align-items-center">
            <Col lg="6" sm="12">
              <FormControlLabel
                name="checkbox1"
                control={<Checkbox checked={checked} onChange={handleChange} style={{ color: "#00A651" }} />}
                label="I agree to the Terms & Conditions"
              />
              {show === true ? <p style={{ color: "red", marginLeft: 25 }}>Please agree to the Terms & Conditions.</p> : null}
            </Col>
            <Col lg="6" sm="12">
              <a href="https://testdscbucket.s3.ca-central-1.amazonaws.com/T&C.pdf" target="_blank" className={classes.downlink} download>
                <div style={{ display: 'flex', gridGap: '10px', alignItems: 'center' }}>
                  <i className="far fa-download"></i>
                  <Typography component="div">
                    Download Terms &
                    Conditions PDF
                  </Typography>
                </div>
              </a>
            </Col>
          </Row>

          {/* <Row className="mt-1 text-end"> */}
          <div className="mt-3 text-end">
            <CustomButtons.OutlineColorButton onClick={handleBack}>
              Back
            </CustomButtons.OutlineColorButton>{" "}
            <Button
              type="submit"
              onSubmit={handleSubmit}
              className="pay-now-button"
            >
              Pay Now
            </Button>
            {/* <CustomButtons.ColorButton disabled={!checked}>
                  Pay Now
                </CustomButtons.ColorButton> */}
          </div>
          {/* </Row> */}

        </form>
      </Container>
    </>
  );
};

export default CheckoutForm;
