import { combineReducers } from "redux";
import RegisterSchoolStore from "./registerSchoolStore";
import { PlanStore, PlanbyidStore } from "./PlanStore";
import SchoolStore from "./SchoolStore";
import CountryStore from "./CountryStore";
import ContactUs from "./ContactIs";

export default combineReducers({
  register: RegisterSchoolStore,
  planList: PlanStore,
  Planbyid: PlanbyidStore,
  schoolList: SchoolStore,
  countryList: CountryStore,
  contacUs:ContactUs,
});
