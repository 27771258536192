import { REGISTRATION } from "../ActionTypes";

const registerStoreData: any = localStorage.getItem("school-register");
const initialState = {
  data: JSON.parse(registerStoreData),
};

const registerSchoolStore = (state = initialState, action: any = {}) => {
  const { type, payload } = action;
  switch (type) {
    case REGISTRATION: {
      // localStorage.setItem("school-register", JSON.stringify(payload.data));
      return {
        ...state,
        data: payload.data,
      };
    }
    default:
      return state;
  }
};

export default registerSchoolStore;
