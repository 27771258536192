import React, { useState, useEffect } from "react";
import { Navbar, Nav, Form, Container, Row, Col } from "react-bootstrap";
import CustomButtons from "../CustomButtons/Button";
import { Link, useHistory } from "react-router-dom";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import Main_svg from "../svg/main_12.svg";

import { ReactSVG } from "react-svg";
import bgImage from "../../assets/images/Path-3676.png";
import aniSvg from "../../assets/images/svg/main.svg";
import aboutImage from "../../assets/images/svg/about_main.svg";
import Circle from "../../assets/images/bg5.png";
import { ROUTES } from "../../constants/routes";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "38px",
    fontWeight: 700,
    color: "#000000",
  },
  subTitle: {
    fontSize: "20px",
    marginTop: "20px",
  },
  button: {
    marginTop: "20px",
  },
  Title_2: {
    fontSize: "36px",
    fontWeight: 700,
    color: "#000000",
  },
  subTitle_2: {
    fontSize: "20px",
    marginTop: "20px",
  },
  linkButton: {
    textDecoration: "none",
  },
  // bgimg1: {
  //     position: 'absolute',
  //     top: '100%',
  //     left: '100%',
  //     right: 'auto',
  //     width: '8%',
  // }
}));

export default function About_us() {
  const history = useHistory();
  const classes = useStyles();
  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, []);
  return (
    <div>
      <section className="hero-2" id="home">
        <div className="bg-overlay-img"></div>
        <div className="container">
          <div className="row align-items-center justify-content-center hero-content">
            <div className="col-lg-4">
              <Typography
                className="hero-title fw-bold mb-2 display-5"
                variant="h3"
                component="div"
              >
                One Stop <br /> Solution For Your <br /> Driving School
              </Typography>
              <Typography className="opacity-75 mb-2 pb-3 fs-17" variant="subtitle1" component="div">
              Managing a driving school is easier then ever.
              </Typography>
              <Link to={ROUTES.getStarted} className={classes.linkButton}>
                <CustomButtons.OutlineColorButton>
                  Schedule Demo
                </CustomButtons.OutlineColorButton>{" "}
              </Link>
              <Link to={ROUTES.getStarted} className={classes.linkButton}>
                <CustomButtons.ColorButton>
                  Buy Now
                </CustomButtons.ColorButton>
              </Link>
            </div>
            <div className="col-lg-8">
              <div className="hero-2-img mt-5 mt-lg-0">
                <object type="image/svg+xml" data={aniSvg}></object>
              </div>
            </div>
          </div>
        </div>
      </section>



      <section className="section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5">
              <img src={aboutImage} />
            </div>
            <div className="col-lg-6 offset-lg-1">
              <Typography
                variant="h3"
                className="fs-38 mb-4"
              >
                What is Driving School Cloud?
              </Typography>

              <Typography className="text-muted">
                Driving School Cloud is industry-leading Mandatory Entry Level
                Training (MELT) compatible driving school management platform
                that helps in simplifying the driving school-related processes
                and workflows for greater transparency and gives insights into
                your driving school business.
              </Typography>
              <div className={classes.button}>
                <CustomButtons.ColorButton onClick={() => history.push("")}>
                  Show Me More
                </CustomButtons.ColorButton>
              </div>
            </div>
          </div>
        </div>
        <div className="icon-effects-w3-1">
          <img src={Circle} />
        </div>
      </section >


    </div >
  );
}
