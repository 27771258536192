/*
  Login messages
*/
export const REGISTER_SUCCESSFULLY = "School register successfully";
export const CHECK_SUBDOMAIN_ERROR = "Subdomain already used";
export const CHECK_EMAIL_ERROR = "Email already used";
export const CHECK_MOBILE_ERROR = "Mobile already used";
export const OTHER_SERVICE_MAIL_SEND_SUCCESSFULLY = "Extra service mail send successfully"
/*
  Error message
*/
export const ALL_ERROR_MESSAGE = "Getting error while processing, Try again!";
