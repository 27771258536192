export const REGISTRATION = "SCHOOL_REGISTRATION";
export const REGISTRATION_FAIL = "SCHOOL_REGISTRATION_FAIL";
export const ListOfSchool = "List_Of_School";

export const PLANLIST = "PLAN_LIST";
export const PLANLIST_FAIL = "PLAN_LIST_FAIL";
export const PLANLBYID = "PLANLBYID";
export const PLANLBYID_FAIL = "PLANLBYID_FAIL";
export const PROVINCELIST = "PROVINCE_LIST";
export const PROVINCELIST_FAIL = "PROVINCE_LIST_FAIL";
export const SELECTED_PLAN_FROM_HOME = "SELECTED_PLAN_FROM_HOME";

export const SCHOOL_LIST = "SCHOOL_LIST";
export const SCHOOL_LIST_FAIL = "SCHOOL_LIST_FAIL";

export const STUDENT_LIST = "STUDENT_LIST";
export const STUDENT_LIST_FAIL = "STUDENT_LIST_FAIL";

export const STAFF_LIST = "STAFF_LIST";
export const STAFF_LIST_FAIL = "STAFF_LIST_FAIL";

export const COUNTRY_LIST = "COUNTRY_LIST";
export const COUNTRY_LIST_FAIL = "COUNTRY_LIST_FAIL";

export const CONTACT_US_MAIL = "CONTACT_US_MAIL";
export const CONTACT_US_MAIL_FAIL = "CONTACT_US_MAIL_FAIL";