import FieldError from "./ErrorMessage";

const EMAIL_PATTERN = new RegExp(
  /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
);

const PHONE_PATTERN = new RegExp(/^[+10-9]{13}$/);
const SPECIAL_PATTERN = new RegExp(/^[A-Za-z0-9 ]+$/);
const ADDRESS_PATTERN = new RegExp(/^[#.0-9a-zA-Z\s,-/]+$/);
const BUSINESS_NUMBER = new RegExp(/^[0-9]{9}$/);

export const isRequiredField = (value: string) => value !== "";

export const isSelctionField = (value: string) => value.length > 0;

export const isValidEmail = (email: string) => EMAIL_PATTERN.test(email);

export const isValidBusiness = (digit: string) => BUSINESS_NUMBER.test(digit);

export const isValidPassword = (password: string) => password.length >= 6;

export const isValidNumber = (numberValue: string) => numberValue.length < 10;

export const isValidPhone = (phone: string) => PHONE_PATTERN.test(phone);

export const isValidRegisterID = (rid: string) => SPECIAL_PATTERN.test(rid);
export const isValidAddress = (add: string) => ADDRESS_PATTERN.test(add);

export const isSameAs = (value: string, isSubmitted: boolean, sameAs: string) =>
  isSubmitted && value === sameAs;

export const renderRequiredError = (
  field: string,
  isSubmitted: boolean,
  message: string,
  isCheckValidEmailValue: boolean,
  isCheckValidPhoneValue: boolean,
  isCheckValidRID: boolean,
  isCheckValidAddress: boolean,
  isCheckValidBusiness: boolean,
) => {
  if (isSubmitted && !isRequiredField(field)) {
    return <FieldError message={message} />;
  }
  if (isCheckValidEmailValue) {
    if (isSubmitted && (!isRequiredField(field) || !isValidEmail(field))) {
      return <FieldError message={"Please Enter Valid Email"} />;
    }
  } else {
    if (isCheckValidPhoneValue) {
      if (isSubmitted && (!isRequiredField(field) || !isValidPhone(field))) {
        return <FieldError message={"Please Enter Valid Number"} />;
      }
    } else {
      if (isCheckValidRID) {
        if (isSubmitted && (!isRequiredField(field) || !isValidRegisterID(field))) {
          return <FieldError message={"Special characters are not allowed"} />;
        }
      } else {
        if (isCheckValidAddress) {
          if (isSubmitted && (!isRequiredField(field) || !isValidAddress(field))) {
            return <FieldError message={"Allowed special characters # , - /"} />;
          }
        } else {
          if (isCheckValidBusiness) {
            if (isSubmitted && (!isRequiredField(field) || !isValidBusiness(field))) {
              return <FieldError message={"Please Enter 9 Digit Number"} />;
            }
          }
          if (isSubmitted && !isRequiredField(field)) {
            return <FieldError message={message} />;
          }
        }
      }
    }
  }



};

export const renderSelectionError = (
  field: string,
  isSubmitted: boolean,
  message: string
) => {
  if (isSubmitted && !isSelctionField(field)) {
    return <FieldError message={message} />;
  }
};

export const renderInvalidEmailError = (
  value: string,
  isSubmitted: boolean,
  message: string
) => {
  if (isSubmitted && isRequiredField(value) && !isValidEmail(value)) {
    return <FieldError message={message} />;
  }
  return "";
};

export const renderInvalidPasswordError = (
  value: string,
  isSubmitted: boolean,
  message: string
) => {
  if (isSubmitted && isRequiredField(value) && !isValidPassword(value)) {
    return <FieldError message={message} />;
  }
  return "";
};

export const renderSameAsError = (
  value: string,
  isSubmitted: boolean,
  sameAs: string,
  fieldName: string,
  sameAsFieldName: string
) => {
  if (
    isSubmitted === true &&
    isRequiredField(value) &&
    !isSameAs(value, isSubmitted, sameAs)
  ) {
    return (
      <FieldError
        message={`${fieldName} should be same as ${sameAsFieldName}`}
      />
    );
  }

  return "";
};

export const renderValidPhoneError = (
  value: string,
  isSubmitted: boolean,
  message: string
) => {
  if (isSubmitted && isRequiredField(value) && !isValidPhone(value)) {
    return <FieldError message={message} />;
  }
  return "";
};

export const renderValidNumberError = (
  value: string,
  isSubmitted: boolean,
  message: string
) => {
  if (isSubmitted && isRequiredField(value) && !isValidNumber(value)) {
    return <FieldError message={message} />;
  }
  return "";
};

// export const redirectionAccessDenied = (
//   status: number,
//   errorMessage: string,
//   redirectPath: string,
//   history: any,
//   toast
// ) => {
//   if (status === 404 || status === 401) {
//     history.push({
//       pathname: redirectPath,
//       state: { message: errorMessage, code: status },
//     });
//     return;
//   }
//   if (status === 403) {
//     history.push({
//       pathname: DENIED_ROUTE[1],
//       state: { message: "Do not allow to access", code: status },
//     });
//     return;
//   }
//   toast.error(errorMessage);
// };
