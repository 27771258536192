import React, { useState, useEffect, SetStateAction } from "react";
import CustomButtons from "../CustomButtons/Button";
import { Container, Row, Col } from "react-bootstrap";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useHistory } from "react-router-dom";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CheckIcon from "@material-ui/icons/Check";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as AuthActions from "../../reducer-store/actions/authActions";
import { GetPlanList, GetProvinceTax } from "../../reducer-store/Actions";
import PlanOne from "../CustomButtons/PlanOne";
import PlanTwo from "../CustomButtons/PlanTwo";
import { PLANLBYID } from "../../reducer-store/ActionTypes";
import { PLATINUM, STANDARD } from "../../reducer-store/constants";

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: "#000000",
  },
  accordion: {
    backgroundColor: "#000000",
    color: "#ffffff",
  },
  ExpandMoreIcon: {
    color: "#ffffff",
  },
  CardContent: {
    minHeight: "600px",
  },
  plan_title: {
    fontSize: "16px",
    fontWeight: 700,
    color: "#000000",
  },
}));

type PlanOverviewProps = {
  handleStep: React.Dispatch<SetStateAction<number>>;
  stepValue: number;
};

export default function PlanOverview({
  handleStep,
  stepValue,
}: PlanOverviewProps) {
  const schoolPlanList: any = useSelector((state: any) => state.planList);
  const { data, selectedPlanFromHome } = schoolPlanList;
  const [isSubmit, setIsSubmit] = useState(false);
  // const [isValidValues, setIsValidValues] = useState(true);
  const [loaded, setLoader] = useState(false);
  const [selectedValue, setSelectedValue] = useState(selectedPlanFromHome || STANDARD);
  const [planData, setPlanData] = useState(data && data[0]);
  const [provincialTax, setProvincialTax] = useState<any>("");
  const [countyTax, setCountyTax] = useState<any>("");
  const [totalTax, setTotalTax] = useState("");
  const [totalTaxAmount, setTotalTaxAmount] = useState<any>("");
  const schoolPlanListbyid: any = useSelector((state: any) => state.Planbyid);
  //const { databyid } = schoolPlanListbyid;
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { planList, planListbyid, selectedPlan } = bindActionCreators(AuthActions, dispatch);
  const registerStoreData: any = localStorage.getItem("school-register");
  const registerData = JSON.parse(registerStoreData)

  useEffect(() => {
    (async () => {
      const provinceTaxData: any = await GetProvinceTax(registerData?.province);
      setProvincialTax(provinceTaxData?.tax_slab_1);
      setCountyTax(provinceTaxData?.tax_slab_2);
      setTotalTax(provinceTaxData?.total_tax);
      setTotalTaxAmount((provinceTaxData?.total_tax / 100) * planData?.price)
    })();
  }, [planData]);

  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    localStorage.setItem("school-plan-byid", JSON.stringify(planData));
  }, []);

  useEffect(() => {
    (async () => {
      planListbyid(parseInt(selectedValue));
      const planInfo = await GetPlanList(selectedValue);
      setPlanData(planInfo);
    })();
  }, []);

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(parseInt(event.target.value));
    selectedPlan(event.target.value);
    planListbyid(event.target.value);
    const planInfo = await GetPlanList(event.target.value);
    setPlanData(planInfo);
  };

  const handleBack = () => {
    setTimeout(() => {
      setLoader(false);
      handleStep((stepValue) => stepValue - 1);
    }, 2000);
  };

  const SubmitHandler = () => {
    setIsSubmit(true);
    setLoader(true);
    setTimeout(() => {
      setLoader(false);
      handleStep((stepValue) => stepValue + 1);
    }, 2000);
  };

  return (
    <div>
      <section className="section-top-1">
        <Container className="">
          <Row className="mb-2">
            <Col lg="6">
              <div>
                <img src="assets/images/svg/plan_overview.svg" width="100%" />
              </div>
            </Col>
            <Col lg="6" className="mt-2">
              <Card className="Main_Card">
                {loaded && <LinearProgress />}

                {planData === null ? ("Loading...") : (
                  <CardContent className={classes.CardContent}>
                    <Card className="geatchoice">
                      <CardContent>
                        <Typography className="StLicence" variant="h6" component="div">
                          Please select plan
                        </Typography>
                        {/* <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                        >
                          <FormControlLabel
                            value={data && data[0].id}
                            control={
                              <Radio
                                onChange={handleChange}
                                checked={selectedValue === "1"}
                              />
                            }
                            label={data && data[0].name}
                          />
                          <FormControlLabel
                            value={data && data[1].id}
                            control={
                              <Radio
                                onChange={handleChange}
                                checked={selectedValue === "2"}
                              />
                            }
                            label={data && data[1].name}
                          />
                        </RadioGroup> */}

                        <div className="plans">
                          <label className="plan basic-plan">
                            <input
                              type="radio"
                              value={data && data[0].id}
                              name="plan"
                              id="complete"
                              onChange={handleChange}
                              checked={selectedValue === STANDARD}
                            />
                            <div className="plan-content">
                              <div className="plan-details">
                                <span>{data && data[0].name}</span>
                              </div>
                            </div>
                          </label>

                          <label className="plan complete-plan">
                            <input
                              type="radio"
                              id="complete"
                              value={data && data[1].id}
                              name="plan"
                              onChange={handleChange}
                              checked={selectedValue === PLATINUM}
                            />
                            <div className="plan-content">
                              <div className="plan-details">
                                <span>{data && data[1].name}</span>
                              </div>
                            </div>
                          </label>
                        </div>

                        {selectedValue === STANDARD ? (
                          <PlanOne planData={planData} />
                        ) : (
                          <PlanTwo planData={planData} />
                        )}
                      </CardContent>
                    </Card>
                    <br />
                    <Card className="geatchoice">
                      <CardContent>
                        <Row>
                          <Typography
                            className="StLicence"
                            variant="h6"
                            component="div"
                          >
                            Order Summary
                          </Typography>
                          <Col lg="8" sm="8" xs="8">
                            <Typography className="Sttext1" variant="body2">
                              {selectedValue === PLATINUM ? 'Premium Plan' : 'Standard Plan'}
                            </Typography>
                          </Col>
                          <Col lg="2" sm="4" xs="4" className="summary">
                            <Typography className="text-end" variant="body2">
                              {planData.symbol}
                            </Typography>
                          </Col>
                          <Col lg="2" sm="4" xs="4" className="summary">
                            <Typography className="text-end" variant="body2">
                              {`${(planData.price).toFixed(2)}`}
                            </Typography>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="8" sm="8" xs="8">
                            <Typography className="Sttext1" variant="body2">
                              Provincial/Territorial Sales Tax ({provincialTax}%)
                            </Typography>
                          </Col>
                          <Col lg="2" sm="4" xs="4" className="summary">
                            <Typography className="text-end" variant="body2">
                              {planData.symbol}
                            </Typography>
                          </Col>
                          <Col lg="2" sm="4" xs="4" className="summary">
                            <Typography className="text-end" variant="body2">
                              {`${((provincialTax / 100) * planData?.price).toFixed(2)}`}
                            </Typography>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="8" sm="8" xs="8">
                            <Typography className="Sttext1" variant="body2">
                              GST/HST ({countyTax}%)
                            </Typography>
                          </Col>
                          <Col lg="2" sm="4" xs="4" className="summary">
                            <Typography className="text-end" variant="body2">
                              {planData.symbol}
                            </Typography>
                          </Col>
                          <Col lg="2" sm="4" xs="4" className="summary">
                            <Typography className="text-end" variant="body2">
                              {` ${((countyTax / 100) * planData?.price).toFixed(2)}`}
                            </Typography>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="8" sm="8" xs="8">
                            <Typography className="Sttext1" variant="body2">
                              Combined Tax ({provincialTax + countyTax}%)
                            </Typography>
                          </Col>
                          <Col lg="2" sm="4" xs="4" className="summary">
                            <Typography className="text-end" variant="body2">
                              {planData.symbol}
                            </Typography>
                          </Col>
                          <Col lg="2" sm="4" xs="4" className="summary">
                            <Typography className="text-end" variant="body2">
                              {`${(((provincialTax / 100) * planData?.price) + ((countyTax / 100) * planData?.price)).toFixed(2)}`}
                            </Typography>
                          </Col>
                        </Row>
                        <div className="stline"></div>
                        <Row>
                          <Col lg="8" sm="8" xs="8">
                            <Typography
                              className="StLicence"
                              variant="h6"
                              component="div"
                            >
                              <b>Total Amount</b>
                            </Typography>
                          </Col>
                          <Col lg="4" sm="4" xs="4" className="summary">
                            <Typography
                              className="text-end"
                              variant="h6"
                              component="div"
                            >
                              <b>{`${planData?.symbol} ${(new Number(planData?.price + totalTaxAmount)).toFixed(2)}`}</b>
                            </Typography>
                          </Col>
                        </Row>
                      </CardContent>
                    </Card>
                  </CardContent>
                )}
                <CardContent>
                  <div className="text-end">
                    <CustomButtons.OutlineColorButton onClick={handleBack}>
                      Back
                    </CustomButtons.OutlineColorButton>{" "}
                    <CustomButtons.ColorButton onClick={SubmitHandler}>
                      Next
                    </CustomButtons.ColorButton>
                  </div>
                </CardContent>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
}
