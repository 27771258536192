export const API_END_POINT = process.env.REACT_APP_BACK_END_API;
export const config = {
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
};

export const SCHOOL_REGISTER_API = API_END_POINT + "/school";
export const NEW_SCHOOL_REGISTER_API = API_END_POINT + "/school/register";
export const CONTACT_US = API_END_POINT + "/contact-us";
export const SCHOOL_LIST_API = API_END_POINT + "/school";
export const STAFF_LIST_API = API_END_POINT + "/staffData";
export const STUDENT_LIST_API = API_END_POINT + "/studentData";
export const CHECK_EMAIL_MOBILE = API_END_POINT + "/school/validate";
export const PLAN_LIST_API = API_END_POINT + "/plan";
export const GET_SINGLE_PLAN_DETAIL = API_END_POINT + "/plan";
export const PLAN_LIST_API_BY_ID = API_END_POINT + "/plan/";
export const GET_PROVINCE_LIST = API_END_POINT + "/country";
export const GET_PROVINCE_TAX = API_END_POINT + "/provinceTax";

export const PAYMENT_INIT = API_END_POINT + "/payment/init";
export const COUNTRY_LIST = API_END_POINT + "/country";

export const STANDARD = 1;
export const PLATINUM = 2;

export const TAXLIMIT = 15;
export const TEXTLIMIT = 50;
export const PHONELIMITE = 10;
export const CONTACTNO = 13;
export const BUSINESS_NUMBER = 9;
export const LARGELIMIT = 100;