import { useState, useEffect } from "react";
// import MakePayment from "./MakePayment";
// import PlanAddons from "./PlanAddons";
import PlanOverview from "./PlanOverview";
import Registration from "./Registration";
import { Box, Stepper, Step, StepLabel, StepIconProps } from "@material-ui/core";
import { Check } from "@material-ui/icons";
import ToastMessage from "../CustomButtons/ToastMessage";
import ElementComp from "./StripePayment/ElementComp";
import "./style.css";

const steps = ["Step 1", "Step 2", "Step 3"];

export default function GetStarted() {
  const urlParams = new URLSearchParams(window.location.search);
  const myParam = urlParams.get("step");
  urlParams.set("redirect_status", "");
  const [activeStep, setActiveStep] = useState(myParam ? parseInt(myParam) : 1);
  const [businessLogo, setBusinessLogo] = useState<any>();

  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, []);

  function QontoStepIcon(props: StepIconProps) {
    const { completed } = props;

    return (
      <>
        {completed ? (
          <Check className="QontoStepIcon-completedIcon" />
        ) : (
          <div className="QontoStepIcon-circle" />
        )}
      </>
    );
  }

  return (
    <div>
      <section className="section-top-2">
        {/* <Container className="">
          <Row> */}
        <ToastMessage />
        <Box sx={{ width: "100%" }}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step className="stepper-css" key={label}>
                <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {activeStep === 1 ? (
            <Registration handleStep={setActiveStep} stepValue={activeStep} handleLogo={setBusinessLogo} />
          ) : activeStep === 2 ? (
            <PlanOverview handleStep={setActiveStep} stepValue={activeStep} />
          ) : activeStep === 3 ? (
            <ElementComp handleStep={setActiveStep} stepValue={activeStep} logo={businessLogo} />
          ) : ("")}
        </Box>
        {/* </Row>
        </Container> */}
      </section>
    </div>
  );
}
