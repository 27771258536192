import React from "react";
import { COUNTRY_LIST } from "../ActionTypes";

const initialState = { list: null };

const CountryStore: React.FC = (
  state: any = initialState,
  action: any = {}
) => {
  const { type, payload } = action;
  switch (type) {
    case COUNTRY_LIST: {
      return {
        ...state,
        list: payload.data,
      };
    }
    default:
      return state;
  }
};

export default CountryStore;
