import React, { useState, useEffect, SetStateAction } from "react";
import { Container, Row, Col } from "react-bootstrap";
import CustomButtons from "../CustomButtons/Button";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import LinearProgress from "@material-ui/core/LinearProgress";
import { SchoolSetupStateProps } from "../../ts/FormType";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as AuthActions from "../../reducer-store/actions/authActions";
import {
  InputField,
  InputSelectField,
} from "../FormComponent/FormFieldsComponent";
import { isRequiredField, isValidEmail, isValidPhone, isValidRegisterID, isValidAddress, isValidBusiness } from "../FormComponent/FormValidator";
import { REGISTRATION } from "../../reducer-store/ActionTypes";
import { toast } from "react-toastify";
import { CHECK_EMAIL_ERROR, CHECK_MOBILE_ERROR, CHECK_SUBDOMAIN_ERROR } from "../../reducer-store/messages/actionMessages";
import {
  CheckEmailMobile,
  GetProvinceList,
  // GetProvinceTax,
  // GetCountryAndProvinceList,
} from "../../reducer-store/Actions";
import { DesignServicesSharp, Label } from "@mui/icons-material";
import { Button, Typography } from "@material-ui/core";
import InputAdornment from "@mui/material/InputAdornment";
import Input from "@mui/material/Input";
import FieldError from "../FormComponent/ErrorMessage";
import { FaSquare } from "react-icons/fa";
import { TEXTLIMIT, LARGELIMIT, BUSINESS_NUMBER, TAXLIMIT, CONTACTNO } from "../../reducer-store/constants";

const useStyles = makeStyles((theme) => ({
  CardContent: {
    minHeight: "600px",
  },
  sunDomainTitle: {
    height: 16,
    marginBottom: 2,
  },
}));

type RegisterProps = {
  handleStep: React.Dispatch<SetStateAction<number>>;
  stepValue: number;
  handleLogo: any;
};

type RegisterSchoolProp = {
  data: any;
};

export default function Registration({ handleStep, stepValue, handleLogo }: RegisterProps) {
  const registerSchool: RegisterSchoolProp = useSelector(
    (state: any) => state.register
  );
  const { data } = registerSchool;
  const [schoolSetup, setSchoolSetup] = useState<SchoolSetupStateProps>({
    name: "",
    email: "",
    phone: "",
    address: "",
    document: null,
    school_user_1: "",
    school_user_2: "",
    currency: "",
    password: "",
  });

  const [fileList, setFileList] = useState();
  const onDropzoneAreaChange = (files: any) => {
    setFileList(files);
  };
  const [businessName, setBusinessName] = useState((data && data.name) || "");
  const [subdomain, setSubdomain] = useState((data && data.subdomain) || "");
  /*   const [ownerName, setOwnerName] = useState((data && data.owner_name) || ""); */
  const [firstName, setFirstName] = useState((data && data.first_name) || "");
  const [middleName, setMiddleName] = useState((data && data.middle_name) || "");
  const [lastName, setLastName] = useState((data && data.last_name) || "");
  const [phoneNumber, setPhoneNumber] = useState((data && data.mobile) || "+1");
  const [mobileNumber, setMobileNumber] = useState((data && data.mobile) || "+1");
  const [whatsappNumber, setWhatsappNumber] = useState(
    (data && data.whatsapp) || ""
  );
  const [businessNumber, setBusinessNumber] = useState(
    (data && data.business_number) || ""
  );
  const [addressLine1, setAddressLine1] = useState(
    (data && data.address_1) || ""
  );
  const [addressLine2, setAddressLine2] = useState(
    (data && data.address_2) || ""
  );
  const [Country, setCountry] = useState((data && data.country) || "Canada");
  const [Province, setProvince] = useState((data && data.province) || "");
  const [salutation, setSalutation] = useState((data && data.title) || "")
  const [provinceArray, setProvinceArray] = useState([]);
  const [Email, setEmail] = useState((data && data.email) || "");
  const [registryId, setRegistryId] = useState(
    (data && data.registry_id) || ""
  );
  const [taxIdentificationNumber, setTaxIdentificationNumber] = useState(
    (data && data.tin_number) || ""
  );
  const [isSubmit, setIsSubmit] = useState(false);
  const [isValidValues, setIsValidValues] = useState(true);
  const [loaded, setLoader] = useState(false);
  const dispatch = useDispatch();
  const [logoFile, setLogoFile] = useState(null);
  const { schoolRegistration, planList } = bindActionCreators(
    AuthActions,
    dispatch
  );
  const classes = useStyles();
  const [imgError, setImgError] = useState(false);
  const [imgErrorMsg, setImgErrorMsg] = useState("");

  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, []);

  useEffect(() => {
    (async () => {
      await planList();
      const provinceWholeData = await GetProvinceList();
      setProvinceArray(provinceWholeData);
    })();
  }, []);

  const SubmitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmit(true);
    if (
      isRequiredField(businessName) &&
      isValidRegisterID(businessName) &&
      isRequiredField(subdomain) &&
      /* isRequiredField(ownerName) &&
      isValidRegisterID(ownerName) && */
      isRequiredField(salutation) &&
      isRequiredField(firstName) &&
      isValidRegisterID(firstName) &&
      // isRequiredField(middleName) &&
      // isValidRegisterID(middleName) &&
      isRequiredField(lastName) &&
      isValidRegisterID(lastName) &&
      isRequiredField(phoneNumber) &&
      isValidPhone(phoneNumber) &&
      isRequiredField(mobileNumber) &&
      isValidPhone(mobileNumber) &&
      /* isRequiredField(whatsappNumber) && */
      /* isValidPhone(whatsappNumber) && */
      isRequiredField(businessNumber) &&
      isValidBusiness(businessNumber) &&
      isRequiredField(addressLine1) &&
      isValidAddress(addressLine1) &&
      isRequiredField(addressLine2) &&
      isValidAddress(addressLine2) &&
      isRequiredField(Country) &&
      isRequiredField(Province) &&
      isRequiredField(Email) &&
      isValidEmail(Email) &&
      isRequiredField(Country) &&
      // isRequiredField(registryId) &&
      // isValidRegisterID(registryId) &&
      isRequiredField(taxIdentificationNumber) &&
      isValidRegisterID(taxIdentificationNumber) &&
      imageValidationCheck(logoFile)
    ) {
      setLoader(true);
      setIsSubmit(false);
      const isCheck: any = await CheckEmailMobile({
        email: Email,
        mobile: mobileNumber,
        subdomain: subdomain,
        registry_id: registryId,
        tin_number: taxIdentificationNumber,
        business_number: businessNumber,
      });

      if (isCheck?.subDomainExists || isCheck?.emailExists || isCheck?.mobileExists || isCheck?.schoolBusinessNumberExist || isCheck?.schoolRegistryExist || isCheck?.schoolTinNumberExist) {
        toast.error(
          <div>
            Following details already exist:
            {isCheck.subDomainExists ? <><br /><FaSquare color="#FFFFFF" size={8} /> Subdomain</> : ""}
            {isCheck.emailExists ? <><br /><FaSquare color="#FFFFFF" size={8} /> Email</> : <></>}
            {isCheck.mobileExists ? <><br /><FaSquare color="#FFFFFF" size={8} /> Mobile Number</> : ""}
            {isCheck.schoolBusinessNumberExist ? <><br /><FaSquare color="#FFFFFF" size={8} /> Business Reg Number</> : ""}
            {isCheck.schoolRegistryExist ? <><br /><FaSquare color="#FFFFFF" size={8} /> Organization ID</> : ""}
            {isCheck.schoolTinNumberExist ? <><br /><FaSquare color="#FFFFFF" size={8} /> Tax Identification Number</> : ""}
          </div >
        );
        setLoader(false);
        return;
      }
      let userObj = {
        name: businessName,
        subdomain: subdomain,
        title: salutation,
        first_name: firstName,
        middle_name: middleName,
        last_name: lastName,
        /* owner_name: null, */
        whatsapp: whatsappNumber,
        business_number: businessNumber,
        email: Email,
        mobile: mobileNumber,
        phone: phoneNumber,
        address_1: addressLine1,
        address_2: addressLine2,
        state: Province,
        registry_id: registryId,
        tin_number: taxIdentificationNumber,
        tenant_id: "123456",
        province: Province,
        status: 1,
      };
      // let resData: any = await schoolRegistration(userObj);
      // if (!resData) {
      //   setLoader(false);
      //   return;
      // }
      // toast.success(REGISTER_SUCCESSFULLY);
      localStorage.setItem("school-register", JSON.stringify(userObj));
      dispatch({
        type: REGISTRATION,
        payload: { data: userObj },
      });
      setTimeout(() => {
        setLoader(false);
        handleStep((stepValue) => stepValue + 1);
      }, 2000);
      return;
    }
    setIsValidValues(false);
  };

  const imageValidationCheck = (fileInfo: any) => {
    if (fileInfo === null) {
      setImgErrorMsg("Please upload school logo");
      setImgError(true)
      return false;
    }
    let typeImage = ["image/jpeg", "image/jpg", "image/png"];
    if (typeImage.includes(fileInfo.type)) {
      setImgError(false)
      return true;
    } else {
      setImgError(true)
      setImgErrorMsg("Please upload valid image(jpg,jpeg,png).");
      return false;
    }
  }

  const handleChangeUploadEvent = (event: any) => {
    const fileInfo = event.target.files[0];
    if (imageValidationCheck(fileInfo)) {
      setSchoolSetup({
        ...schoolSetup,
        [event.target.name]: URL.createObjectURL(fileInfo),
      });
      setLogoFile(fileInfo);
      handleLogo(fileInfo);
      // setIsValidValues(true);
      return;
    }
    setIsValidValues(false);
    setSchoolSetup({
      ...schoolSetup,
      [event.target.name]: "",
    });
    setLogoFile(fileInfo);
    handleLogo(fileInfo);
    return;
  };

  const allowOnlyNumber = (value: string, setMethod: any) => {
    const re = /^[+10-9\b]+$/;
    if (value === '' || re.test(value))
      setMethod(value);
  }

  const allowOnlyAlphaNum = (value: string, setMethod: any) => {
    const re = /^[A-Za-z0-9 ]+$/;
    if (value === '' || re.test(value))
      setMethod(value);
  }

  const handleBusinessName = (name: any) => {
    setBusinessName(name);
    setSubdomain(name.replace(/ /g, ""));
  };

  const HandleSubDomain = (text: any) => {
    const regex = /^[0-9a-zA-Z(\-)]+$/;
    if (text.match(regex) || text === "") {
      setSubdomain(text);
    }
  }

  return (
    <div>
      <section className="section-top-1">
        <Container className="">
          <Row className="mb-2">
            <Col
              lg="6"
              className="d-flex flex-column justify-content-center col-md-push-6 mb-1"
            >
              <div>
                <img src="assets/images/svg/registration.svg" width="100%" />
              </div>
            </Col>
            <Col lg="6" className="mt-2">
              <Card className="Main_Card">
                {loaded && <LinearProgress />}
                <form onSubmit={SubmitHandler}>
                  <CardContent className={classes.CardContent}>
                    <Row>
                      <Col lg="6" sm="12" className="lableMain">
                        <InputField
                          type="text"
                          lable="Business Name"
                          name={businessName}
                          rows={1}
                          multiline={false}
                          placeHolder="Enter Business Name"
                          className="form-textfield-styles"
                          value={businessName}
                          handleChange={(e) => handleBusinessName(e.target.value)}
                          isValidValue={isValidValues}
                          isSubmit={isSubmit}
                          requiredMessage="Business Name field is required"
                          isCheckValidRID={true}
                        />
                      </Col>
                      <Col lg="6" sm="12" className="lableMain">
                        <Typography variant="subtitle1" className={classes.sunDomainTitle}                        >
                          Sub domain name
                        </Typography>
                        <Input
                          fullWidth
                          id="standard-adornment-weight"
                          value={subdomain}
                          placeholder="Enter Sub domain name"
                          onChange={(e) => HandleSubDomain(e.target.value.trim())}
                          endAdornment={
                            <InputAdornment position="end">
                              .drvsc.com
                            </InputAdornment>
                          }
                          aria-describedby="standard-weight-helper-text"
                          inputProps={{ "aria-label": "weight", maxLength: TEXTLIMIT }}
                        />
                        {isSubmit && subdomain === "" && (<FieldError message="Please enter subdomain." />)}
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col lg="6" sm="12" className="lableMain">
                        <InputSelectField
                          select={true}
                          lable="Salutation"
                          name={salutation}
                          value={salutation}
                          options={[
                            { id: "Mr", name: "Mr" },
                            { id: "Ms", name: "Ms" },
                            { id: "Mrs", name: "Mrs" },
                          ]}
                          className="form-textfield-styles"
                          handleChange={(e) => setSalutation(e.target.value)}
                          isValidValue={isValidValues}
                          isSubmit={isSubmit}
                          requiredMessage="Salutation field is required"
                        />
                      </Col>
                      <Col lg="6" sm="12" className="lableMain">
                        <InputField
                          type="text"
                          lable="First Name"
                          name={firstName}
                          rows={1}
                          multiline={false}
                          placeHolder="Enter Owner First Name"
                          className="form-textfield-styles"
                          value={firstName}
                          handleChange={(e) => setFirstName(e.target.value)}
                          isValidValue={isValidValues}
                          isSubmit={isSubmit}
                          requiredMessage="First Name field is required"
                          isCheckValidRID={true}
                          max={TEXTLIMIT}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col lg="6" sm="12" className="lableMain">
                        <InputField
                          type="text"
                          lable="Middle Name"
                          name={middleName}
                          rows={1}
                          multiline={false}
                          placeHolder="Enter Owner Middle Name"
                          className="form-textfield-styles"
                          value={middleName}
                          handleChange={(e) => setMiddleName(e.target.value)}
                          isValidValue={false}
                          isSubmit={false}
                          requiredMessage="Middle Name field is required"
                          isCheckValidRID={true}
                          max={TEXTLIMIT}
                        />
                      </Col>
                      <Col lg="6" sm="12" className="lableMain">
                        <InputField
                          type="text"
                          lable="Last Name"
                          name={lastName}
                          rows={1}
                          multiline={false}
                          placeHolder="Enter Owner Last Name"
                          className="form-textfield-styles"
                          value={lastName}
                          handleChange={(e) => setLastName(e.target.value)}
                          isValidValue={isValidValues}
                          isSubmit={isSubmit}
                          requiredMessage="Last Name field is required"
                          isCheckValidRID={true}
                          max={TEXTLIMIT}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col lg="6" sm="12" className="lableMain">
                        <InputField
                          type="text"
                          lable="Phone Number"
                          name={phoneNumber}
                          rows={1}
                          multiline={false}
                          placeHolder="Enter Phone Number"
                          className="form-textfield-styles"
                          value={phoneNumber}
                          handleChange={(e) => allowOnlyNumber(e.target.value, setPhoneNumber)}
                          isValidValue={isValidValues}
                          isSubmit={isSubmit}
                          requiredMessage="Phone Number field is required"
                          isCheckValidPhoneValue={true}
                          max={CONTACTNO}
                        />
                      </Col>
                      <Col lg="6" sm="12" className="lableMain">
                        <InputField
                          type="text"
                          lable="Mobile Number"
                          name={mobileNumber}
                          rows={1}
                          multiline={false}
                          placeHolder="Enter Mobile Number"
                          className="form-textfield-styles"
                          value={mobileNumber}
                          handleChange={(e) => allowOnlyNumber(e.target.value, setMobileNumber)}
                          isValidValue={isValidValues}
                          isSubmit={isSubmit}
                          requiredMessage="Mobile Number field is required"
                          isCheckValidPhoneValue={true}
                          max={CONTACTNO}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col lg="6" sm="12" className="lableMain">
                        <InputField
                          type="text"
                          lable="Whatsapp Number"
                          name={whatsappNumber}
                          rows={1}
                          multiline={false}
                          placeHolder="Enter Whatsapp Number"
                          className="form-textfield-styles"
                          value={whatsappNumber}
                          handleChange={(e) => allowOnlyNumber(e.target.value, setWhatsappNumber)}
                          isValidValue={false}
                          isSubmit={false}
                          requiredMessage="Whatsapp Number field is required"
                          isCheckValidPhoneValue={true}
                          max={CONTACTNO}

                        />
                      </Col>
                      <Col lg="6" sm="12" className="lableMain">
                        <InputField
                          type="text"
                          lable="Business Reg Number"
                          name={businessNumber}
                          rows={1}
                          multiline={false}
                          placeHolder="Enter Business Number"
                          className="form-textfield-styles"
                          value={businessNumber}
                          handleChange={(e) => allowOnlyNumber(e.target.value, setBusinessNumber)}
                          isValidValue={isValidValues}
                          isSubmit={isSubmit}
                          requiredMessage="Business Number field is required"
                          isCheckValidBusiness={true}
                          max={BUSINESS_NUMBER}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col lg="6" sm="12" className="lableMain">
                        <InputField
                          type="text"
                          lable="Address Line 1"
                          name={addressLine1}
                          rows={1}
                          multiline={false}
                          placeHolder="Enter Address Line 1"
                          className="form-textfield-styles"
                          value={addressLine1}
                          handleChange={(e) => setAddressLine1(e.target.value)}
                          isValidValue={isValidValues}
                          isSubmit={isSubmit}
                          requiredMessage="Address 1 field is required"
                          max={LARGELIMIT}
                          isCheckValidAddress={true}
                        />
                      </Col>

                      <Col lg="6" sm="12" className="lableMain">
                        <InputField
                          type="text"
                          lable="Address Line 2"
                          name={addressLine2}
                          rows={1}
                          multiline={false}
                          placeHolder="Enter Address Line 2"
                          className="form-textfield-styles"
                          value={addressLine2}
                          handleChange={(e) => setAddressLine2(e.target.value)}
                          isValidValue={isValidValues}
                          isSubmit={isSubmit}
                          requiredMessage="Address 2 field is required"
                          max={LARGELIMIT}
                          isCheckValidAddress={true}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col lg="6" sm="12" className="lableMain">
                        <InputField
                          type="text"
                          lable="Country"
                          name={Country}
                          rows={1}
                          multiline={false}
                          placeHolder="Enter Country"
                          className="form-textfield-styles"
                          value={Country}
                          handleChange={(e) => setCountry(e.target.value)}
                          isValidValue={isValidValues}
                          isSubmit={isSubmit}
                          requiredMessage="Country field is required"
                          disabled={true}
                        />
                      </Col>

                      <Col lg="6" sm="12" className="lableMain">
                        <InputSelectField
                          select={true}
                          lable="Province"
                          name={Province}
                          value={Province}
                          className="form-textfield-styles"
                          options={provinceArray}
                          handleChange={(e) => setProvince(e.target.value)}
                          isValidValue={isValidValues}
                          isSubmit={isSubmit}
                          requiredMessage="Province field is required"
                        />
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col lg="6" sm="12" className="lableMain">
                        <InputField
                          type="text"
                          lable="Email"
                          name={Email}
                          rows={1}
                          multiline={false}
                          placeHolder="Enter Email"
                          className="form-textfield-styles"
                          value={Email}
                          handleChange={(e) => setEmail(e.target.value)}
                          isValidValue={isValidValues}
                          isSubmit={isSubmit}
                          requiredMessage="Valid email required"
                          isCheckValidEmailValue={true}
                          max={TEXTLIMIT}
                        />
                      </Col>

                      <Col lg="6" sm="12" className="lableMain">
                        <InputField
                          type="text"
                          lable="Organization ID"
                          name={registryId}
                          rows={1}
                          multiline={false}
                          placeHolder="Enter Organization ID"
                          className="form-textfield-styles"
                          value={registryId}
                          handleChange={(e) => allowOnlyAlphaNum(e.target.value, setRegistryId)}
                          isValidValue={false}
                          isSubmit={false}
                          requiredMessage="Organization ID field is required"
                          isCheckValidRID={true}
                          max={TEXTLIMIT}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col lg="6" sm="12" className="lableMain">
                        <InputField
                          type="text"
                          lable="Tax Identification Number"
                          name={taxIdentificationNumber}
                          rows={1}
                          multiline={false}
                          placeHolder="Enter Tax Identification Number"
                          className="form-textfield-styles"
                          value={taxIdentificationNumber}
                          handleChange={(e) => setTaxIdentificationNumber(e.target.value)}
                          isValidValue={isValidValues}
                          isSubmit={isSubmit}
                          requiredMessage="Tax Identification field is required"
                          isCheckValidRID={true}
                          max={TAXLIMIT}
                        />
                      </Col>

                      <Col lg="6" sm="12" className="lableMain">
                        <InputLabel shrink>Upload School Logo</InputLabel>
                        <TextField
                          fullWidth
                          type="file"
                          name="document"
                          className="form-textfield-styles"
                          onChange={(e) => handleChangeUploadEvent(e)}
                        />
                        {
                          imgError ? <FieldError message={imgErrorMsg} /> : null
                        }
                      </Col>
                    </Row>
                    <Row className="mt-5">
                      {/*  <Col lg="12" sm="12"> */}
                      {schoolSetup.document ? (
                        <img
                          src={schoolSetup.document}
                          width={100}
                          height={150}
                        />
                      ) : ("")}
                      {/*  </Col> */}
                    </Row>
                  </CardContent>
                  <CardContent>
                    <div className="text-end">
                      <CustomButtons.ColorButton type="submit">
                        Next
                      </CustomButtons.ColorButton>
                    </div>
                  </CardContent>
                </form>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
}
