import React, { useState } from "react";
import { Navbar, Nav, Form, Container } from "react-bootstrap";
// import { Link } from "react-router-dom";
import { useHistory, Link } from "react-router-dom";
import CustomButtons from "../CustomButtons/Button";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { ROUTES } from "../../constants/routes";
import logo from "../../assets/images/logo/dsc_logo.svg";

const useStyles = makeStyles((theme: any) => ({
  header: {
    backgroundColor: "#ededed !important",
    boxShadow: "0 0 0.2em #dddddd",
  },
  navLinksStyle: {
    padding: "0.5rem",
    color: "#0000008c",
    textDecoration: "none",
  },
  navLinkHoverEffect: {
    "&:hover": {
      backgroundColor: "transparent",
      color: "#00a651",
    },
  },
  linkButton: {
    textDecoration: "none",
  },
}));

function Header() {
  const history = useHistory();
  const classes = useStyles();

  return (
    <>
      {/* <Navbar className="fixed-top" expand="lg" id="header"> */}
      {/* <Container>
        <Navbar.Brand>
          <Link to="">
            <img src={logo} />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll" className="justify-content-end">
          <Nav className="Navbar">
            <Link
              className={`nav-links ${classes.navLinksStyle} ${classes.navLinkHoverEffect}`}
              to={ROUTES.home}
            >
              Home
            </Link>
            <Link
              className={`nav-links ${classes.navLinksStyle} ${classes.navLinkHoverEffect}`}
              to={ROUTES.home}
            >
              Features
            </Link>
            <Link
              className={`nav-links ${classes.navLinksStyle} ${classes.navLinkHoverEffect}`}
              to={ROUTES.home}
            >
              Plans
            </Link>
            <Link
              className={`nav-links ${classes.navLinksStyle} ${classes.navLinkHoverEffect}`}
              to={ROUTES.home}
            >
              Download
            </Link>
          </Nav>
          <Link to={ROUTES.getStarted} className={classes.linkButton}>
            <CustomButtons.ColorButton>Get started</CustomButtons.ColorButton>
          </Link>
        </Navbar.Collapse>
      </Container> */}
      {/* </Navbar> */}

      <nav className="navbar navbar-expand-lg fixed-top navbar-custom sticky-dark" id="navbar-sticky">
        <div className="container">
          <a className="logo text-uppercase" href="/">
            <img src={logo} alt="" className="logo-image" />
          </a>

          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
            <i className="mdi mdi-menu"></i>
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <ul className="navbar-nav mx-auto navbar-center" id="mySidenav">
              <li className="nav-item">
                <a href="/#home" className="nav-link">Home</a>
              </li>
              <li className="nav-item">
                <a href="/#features" className="nav-link">Features</a>
              </li>
              <li className="nav-item">
                <a href="/#pricing" className="nav-link">Plan</a>
              </li>
            </ul>
            <ul className="navbar-nav navbar-center text-center mb-2 mt-2">
              <li className="nav-item">
                <Link to={ROUTES.getStarted} className={classes.linkButton}>
                  <CustomButtons.ColorButton>Get started</CustomButtons.ColorButton>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>



    </>

  );
}
export default Header;
